<template>
  <v-container>
    <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="loading"
      ></v-progress-linear>
    <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-show="internalLoading"
      ></v-progress-linear>
  <div class="px-4 mb-8">
    
    <v-row class="my-4 justify-space-between">
      <v-col cols="10" class="d-flex align-center">
        <h1 class="text text-h4">Orden de Trabajo <span class="titulo text-h4">#{{work_order.id}}</span></h1>
        <div class="d-block ml-6">
          <div class="text caption" title="Creada"> <v-icon class="mr-2" small>fas fa-square-plus</v-icon>{{work_order.created_by.first_name}} {{work_order.created_by.last_name}} - {{work_order.created_at| moment('DD/MM/YYYY h:mm a')}}</div>
          <div v-if="work_order.updated_by" class="text caption" title="Actualizada"><v-icon class="mr-2" small>fas fa-square-pen</v-icon>{{work_order.updated_by.first_name}} {{work_order.updated_by.last_name}} - {{work_order.updated_at| moment('DD/MM/YYYY h:mm a')}}</div>
        </div>
      </v-col>
      <v-col class="text-end">
        <div class="flex justify-space-around">
            <v-menu offset-y bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  offset-x
                  fab
                  small
                  dense
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item>
                  <print-work-order :workorder="work_order"/>
                </v-list-item>
                <v-list-item v-if="$can('readCost', 'SalesNote')">
                  <v-btn text small @click.stop="generateCostReport(work_order)" class="dark_blue--text"><v-icon class="mr-2">fas fa-calculator</v-icon>Informe de Costos</v-btn>
                </v-list-item>

                <!-- Integracion Softnet -->
                <v-list-item v-if="$can('create','Softnet')&&$store.getters.getCompany.softnet_integration_enabled">
                  <softnet-button :sales_note="work_order.sales_note" />
                </v-list-item>
              <!-- Integracion Softnet -->

                <Can I="update" a="WorkOrder">
                  <v-list-item v-if="!work_order.completed">
                    <v-btn dense small text @click.stop="update" class="mr-2" ><v-icon class="mr-2">fas fa-pen</v-icon>Modificar</v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn dense small text @click.stop="addingCost=true" class="mr-2" ><v-icon class="mr-2">fas fa-cart-plus</v-icon>Agregar Costo Adicional</v-btn>
                  </v-list-item>
                  <v-list-item v-if="!work_order.completed">
                    <v-btn dense small text @click.stop="showConfirmCloseOrder=true" :disabled="has_prestage" class="mr-2" ><v-icon class="mr-2">fas fa-check-circle</v-icon>Cerrar Orden</v-btn>
                  </v-list-item>
                </Can>

              </v-list>
            </v-menu>
          <v-btn @click="$emit('close')" class="ml-2" fab small ><v-icon small>fas fa-chevron-left</v-icon></v-btn>
          </div>
      </v-col>
      <v-progress-linear
          :value="progressEstimated.progress"
          color="primary"
          height="25"
          rounded
        >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-row>
    <v-row justify="start">
        <v-col cols="12" md="6">
          <v-text-field
            type="text"
            filled
            dense
            :value="work_order.sales_note"
            label="Cotización"
            outlined
            readonly
            hide-details         
            />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.customer_name"
          label="Cliente"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.name"
          label="Proyecto"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.delivery_date|moment('DD/MM/YYYY HH:mm')"
          label="Fecha Comprometida"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6" v-if="work_order.install_date">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.install_date|moment('DD/MM/YYYY HH:mm')"
          label="Instalación"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6" v-if="work_order.uninstall_date">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.uninstall_date|moment('DD/MM/YYYY HH:mm')"
          label="Desinstalación"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        
        <v-col cols="12" md="6"  v-if="work_order.customer_approval">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.approval_notes"
          label="Visto Bueno"
          outlined
          readonly
          hide-details         
          />
        </v-col>
        <v-col cols="12" md="6" v-if="show_estimated_work_order">
          <div v-if="has_prestage">
            <v-icon small>fas fa-hourglass-half</v-icon>
          </div>
          <v-text-field
          v-else-if="progressEstimated.estimated>0"
          type="text"
          filled
          dense
          :value="progressEstimated.estimated|moment('DD/MM/YYYY')"
          label="Fecha Estimada"
          outlined
          readonly
          hide-details         
          />
          <div v-else>
            <v-icon color="success">fas fa-check-circle</v-icon>
          </div>
        </v-col>
        <!-- La Fecha de cierre se revisara para colocar despues -->
        <!-- <v-col cols="12" md="6" v-if="work_order.completed">
          <v-text-field
          type="text"
          filled
          dense
          :value="work_order.completed_date|moment('DD/MM/YYYY HH:mm')"
          label="Fecha de Cierre"
          outlined
          readonly
          hide-details         
          />
        </v-col> -->
        <v-col cols="12">
          <v-textarea
          filled
          dense
          rows="2"
          :value="work_order.notes"
          label="Observaciones"
          outlined
          readonly
          hide-details         
          />  
        </v-col>
        <v-col cols="12" v-if="$store.getters.getCompany.softnet_integration_enabled&&$can('read','Softnet') " class="text-left px-3">
          <softnet-documents-chips class="mt-4" :work_order_id="work_order.id" />
        </v-col>
        <v-col cols="12" v-if="$store.getters.getCompany.kame_integration_enabled&&$can('read','Kame') " class="text-left px-3">
          <kame-documents-chips class="mt-4" :work_order_id="work_order.id" />
        </v-col>
        
    </v-row>
        <v-row>
          <v-tabs
          fixed-tabs
          v-model="tab"
          class="mt-4"
          >
            <v-tab v-model="tab" class="font-weight-bold success--text">Productos</v-tab>
            <v-tab v-model="tab" class="font-weight-bold success--text" v-if="$can('manage', 'WorkOrder')">Adicionales</v-tab>
            <v-tab v-model="tab" class="font-weight-bold success--text" v-if="$can('read', 'PurchaseOrder')">Compras</v-tab>
            <v-tab v-model="tab" class="font-weight-bold success--text" v-if="$can('read', 'Invoice')">Facturas</v-tab>
            <v-tab v-model="tab" class="font-weight-bold success--text" v-if="$can('readCost', 'SalesNote')">Resumen</v-tab>
            <v-tab v-model="tab" class="font-weight-bold success--text" v-if="$can('read', 'WorkOrder')">Documentos</v-tab>
            <v-tabs-items v-model="tab">
              <!-- Productos -->
              <v-tab-item>
                <v-data-table
                :headers="productsTableHeaders"
                :items="work_order.products"
                :single-expand="true"
                expanded.sync="expanded"
                expand-icon="fas fa-chevron-down"
                item-key="id"
                show-expand
                class="flex d-block mt-4"
                
                >
                      <template v-slot:top>
                        <!-- <div class="text text-h6 pa-4">Productos</div> -->
                      </template>
                      <template v-slot:item.name="{item}">
                        {{item.name}}
                      </template>
                      <template v-slot:item.width="{item}">
                        {{item.width | number('0,0.00')}}
                      </template>
                      <template v-slot:item.height="{item}">
                        {{item.height | number('0,0.00')}}
                      </template>
                      <template v-slot:item.qty="{item}">
                        {{item.qty}}
                      </template>
                      <template v-slot:item.totalMts2="{item}">
                        {{item.mts2 | number('0,0.00')}}
                      </template>
                      <template v-slot:item.progress="{item}">
                        <v-progress-linear
                            :value="item.progress"
                            :color="item.progress>0?'primary':'main'"
                            height="25"
                            rounded
                            dark
                            title="Progreso"
                          >
                          <template v-slot:default="{ value }">
                            <strong>{{ Math.floor(value) }}%</strong>
                          </template>
                        </v-progress-linear>
                      </template>
                      <template v-slot:item.estimated="{ item }">
                        <div v-if="item.estimated!=0">
                          {{item.estimated | moment("DD/MM/YYYY")}}
                        </div>
                        <div v-else-if="item.in_prestage" class="text-center">
                          <v-icon small>fas fa-hourglass</v-icon>
                        </div>
                        <div v-else class="text-center">
                          <v-icon color="success">fas fa-check-circle</v-icon>
                        </div>
                      </template>
                      <template v-slot:item.status="{ item }">
                        
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <div  
                              v-bind="attrs"
                              v-on="on">
                              <v-chip
                                v-if="item.in_prestage"
                                class="ma-2"
                                :color="item.prestage.color"
                                label
                                outlined
                                small
                                style="max-width:100px"
                                >
                                {{item.prestage.name}}
                              </v-chip>
                              <v-chip
                                v-else-if="!item.completed"
                                class="ma-2"
                                label
                                dark
                                :color="item.estatus.last_machine!=''?'primary':'main'"
                                style="max-width:100px"
                                x-small
                                title="Última area productiva"
                                >
                                <strong>{{item.estatus.last_machine!=''?item.estatus.last_machine:'...' }}</strong>
                              </v-chip>
                              <v-chip
                                v-else
                                class="ma-2"
                                label
                                dark
                                color="info"
                                style="max-width:100px"
                                x-small
                                >
                                <strong> Finalizado</strong>
                              </v-chip>
                            </div>
                            
                          </template>
                          <div v-if="item.estatus.bitacora_logs.length>0">
                            <v-simple-table dark>
                              <thead class="white--text">
                                <tr>
                                  <th>
                                    Fecha
                                  </th>
                                  <th>
                                    Usuario
                                  </th>
                                  <th>
                                    Area Prod.
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="white--text">
                                <tr v-for='register in item.estatus.bitacora_logs' :key='register.created_at'>
                                  <td>
                                    {{register.created_at | moment("D/MM/YYYY")}}
                                  </td>
                                  <td>
                                    {{register.user}}
                                  </td>
                                  <td>
                                    {{register.machine}}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </div>
                        </v-tooltip>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td class="py-4 px-10" :colspan="headers.length">
                          <product-detail :product="item" @updated="refreshWorkOrder()" :work_order_id="work_order.id"/>
                        </td>
                      </template>
                </v-data-table>
              </v-tab-item>
              <!-- Costos Adicionales -->
              <v-tab-item v-if="$can('manage', 'WorkOrder')">
                <v-data-table
                  :loading="costLoading"
                  :headers="additionalCostsHeaders"
                  :items="additionalCosts"
                  item-key="key"
                  class="flex d-block mt-4"
                  hide-default-footer
                  >
                  <template v-slot:no-data>
                      <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                        No existen costos registrados
                      </v-alert>
                    </template>
                  <template v-slot:top>
                    <!-- <div class="text text-h6 pa-4">Costos Adicionales</div> -->
                  </template>
                  <template v-slot:item.price="{item}">
                    <div class="text">{{item.price | currency('$',0)}}</div>
                  </template>
                  <template v-slot:item.actions="{item}">
                    <v-icon @click="deleteCost(item)"  x-small class="error--text">fas fa-trash</v-icon>
                  </template>
                </v-data-table>
              </v-tab-item>
              <!-- Ordenes de compra -->
              <v-tab-item v-if="$can('read', 'PurchaseOrder')">
                <purchase-orders :workorder="work_order"/>
              </v-tab-item>
              <!-- Facturas -->
              <v-tab-item v-if="$can('read', 'Invoice')">
                <invoices :workorder="work_order" />
              </v-tab-item>
              <!-- Resumen -->
              <v-tab-item  v-if="$can('readCost', 'SalesNote')">
                <running-cost :work_order="work_order"/>
              </v-tab-item>
              <!-- Documentos -->
              <v-tab-item v-if="$can('read', 'WorkOrder')">
                <file-manager 
                :oldFiles="work_order.files.map(f=>{return {...f,delete:false}})" 
                :sales_note="work_order.sales_note"
                @refresh="refreshWorkOrder"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-row>
    </div>
    <v-dialog v-model="addingCost" max-width="500">
      <v-card>
        <cost-create-update @save="addCost" :custom="false" :global="false" :workOrder="true" :cost="newWorkOrderCost" :loading="costLoading"/>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addingInvoice" max-width="1100">
      <v-card class="px-10">
        <invoice-registration 
        @close="()=>{
          addingInvoice=false
          tab=2
          }" 
        :work_order="work_order"/>
      </v-card>
    </v-dialog>
    <confirm
    v-if="work_order"
    v-model="showConfirmCloseOrder" 
    message="Confirma el cambio de Estatus de la Orden?"
    @ok="closeWorkOrder"
    @cancel="showConfirmCloseOrder=false"/>
  </v-container>
</template>
<script>
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import {updateWorkOrder,getWorkOrder} from '@/api/production'
import {getSalesNote} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import ProductDetail from './ProductDetail'
import DetailedPurchaseOrder from '@/components/stocks/DetailedPurchaseOrder'
import RunningCost from './RunningCost'
import {postProcessOrderComplete} from '@/api/production'
import InvoiceDetail from '@/components/sales/InvoiceDetail'
import FileManager from '@/components/utils/FileManager'
import SoftnetDocumentsChips  from '@/components/softnet/SoftnetDocumentsChips'
import KameDocumentsChips  from '@/components/kame/KameDocumentsChips'
import SoftnetButton from '@/components/softnet/SoftnetButton.vue' 
import {pdfCostReport} from '@/doc_generators/costReport'
import store from '@/store'
import PrintWorkOrder from '@/components/production/PrintWorkOrder'
import DistributionList from '@/components/sales/DistributionList'


// NUEVAS IMPORTACIONES
import Invoices from './Invoices.vue'
import PurchaseOrders from './PurchaseOrders.vue'

export default {
  
  name:'Detail',
  components:{CostCreateUpdate,ProductDetail,RunningCost,
  FileManager, SoftnetDocumentsChips,SoftnetButton,KameDocumentsChips,
  Invoices,PurchaseOrders,PrintWorkOrder,DistributionList
  },
  props:['work_order','id','loading'],
  data() {
    return {
      productsTableHeaders:[
        {text:'Código',value:'library_product.sku',class:'table-header2'},
      { text: 'Descripción', value: 'name' ,class:'table-header2'},
      { text: 'Cant.', value: 'qty',class:'table-header2' ,align:'end'},
      {text:'Ancho',value:'width',class:'table-header2',align:'end'},
      {text: 'Alto',value:'height',class:'table-header2',align:'end'},
      { text: 'Mts2', value: 'totalMts2',class:'table-header2',align:'end' },
      {text:'Estatus',value:'status',sortable: false,class:'table-header2',align:'center'},
      { text: 'Avance',value:'progress',sortable: false,class:'table-header2'},
      store.getters.getCompany.show_estimated_work_order?{ text: 'Est.',value:'estimated',sortable: false,class:'table-header2'}:'',
      { text: '', value: 'data-table-expand' },
    ],
    additionalCostsHeaders:[
      { text: 'Descripción', value: 'name' ,class:'table-header'},
      { text: 'Costo', value: 'price' ,class:'table-header'},
      { text: '', value: 'actions' ,class:'table-header',align:'center'},
    ],
    addingCost:false,
    newWorkOrderCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:true,
      has_article:false,
      price_update_option:0
    },
    //additionalCosts:[],
    costLoading:false,
    addingInvoice:false,
    showConfirmCloseOrder:false,
    //Internal loading es para que no se cierre toda la ventana
    //cuando quiero refrescar la OT
    internalLoading:false,
    tab:null,
    closingOrder:false,
    closingOrderMessage:''
    }
  },
  computed:{
    delayed(){
      return this.progressEstimated.estimated? new Date(this.progressEstimated.estimated).toISOString()>this.work_order.delivery_date:''
    },
    buying_orders(){
      return this.work_order.buying_orders
    },
    additionalCosts(){
      return Object.keys(this.work_order.additional_costs).length === 0 && this.work_order.additional_costs.constructor === Object?[]:this.work_order.additional_costs
    },
    has_prestage(){
      return this.work_order.products.some(product=>product.in_prestage)
    },
    show_estimated_work_order(){
      return this.$store.getters.getCompany.show_estimated_work_order
    },
    filtered_product_headers(){
      return this.productsTableHeaders.filter(header=>header.show)
    },
    progressEstimated(){
      let completedMts2=0
        var workOrderEstimatedFinal=[]
        this.work_order.products.forEach(p=>{
          //Calculando la fecha final de entrega del producto
          var estimatedFinal=[]
           p.process_orders.map(process=>{
            estimatedFinal.push(new Date(process.estimated))
          })
          p.estimated=Math.max(...estimatedFinal)
          workOrderEstimatedFinal.push(p.estimated)

          const completed=p.process_orders.filter(c=>c.completed==true)
          p.progress=completed.length*1/p.process_orders.length*100
          completedMts2+=p.progress/100*p.mts2
        })
        return{
          'estimated':Math.max(...workOrderEstimatedFinal),
          'progress':completedMts2/this.work_order.total_mts2*100 
        }
    }
  },
  methods:{
    update(){
      this.$router.push({name:'WorkOrderUpdate',params:{id:this.work_order.id}})
    },
    async generateCostReport(item){
      const {sales_note}=item
      await getSalesNote(sales_note)
      .then(data=>{
        pdfCostReport(data)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    async deleteCost(item){
      const index = this.work_order.additional_costs.indexOf(item);
      this.work_order.additional_costs.splice(index, 1);
      const workOrder={
        id:this.work_order.id,
        additional_costs:this.work_order.additional_costs,
        justUpdateCost:true,
      }
      await updateWorkOrder(workOrder)
      .then((res)=>{
        notifications.showSuccess('Orden de Trabajo '+res.id+' Actualizada')
        this.work_order.additional_costs=res.additional_costs
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        const cost={
        name:item.name,
        price:item.price,
        }
        this.work_order.additional_costs.push(cost)
      })
    },
    async addCost(item){
      this.costLoading=true
      const cost={
        name:item.name,
        price:item.price,
      }
      //Revisa si el objecto es vacío para poder usar la función push. 
      if(Object.keys(this.work_order.additional_costs).length === 0){
        var additional_costs =[]
        additional_costs.push(cost)
        this.work_order.additional_costs = additional_costs
      }
      else{
        this.work_order.additional_costs.push(cost)
      }
      const workOrder={
        id:this.work_order.id,
        additional_costs:this.work_order.additional_costs,
        justUpdateCost:true,
      }
      await updateWorkOrder(workOrder)
      .then((res)=>{
        notifications.showSuccess('Orden de Trabajo '+res.id+' Actualizada')
        this.work_order.additional_costs=res.additional_costs
        this.addingCost=false
        this.costLoading=false
        this.newWorkOrderCost={
          id:-1,
          unit:'',
          name:'',
          price:0,
          min_price:0,
          is_supply:true
        }
        
        this.tab=1
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        const index = this.work_order.additional_costs.indexOf(item);
        this.work_order.additional_costs.splice(index, 1);
      })
    },
    async refreshWorkOrder(){
      this.internalLoading=true
      await getWorkOrder(this.work_order.id)
      .then(async (data)=>{
        this.work_order=data
        notifications.showSuccess('OT Actualizada')

        //Actualizo el resumen
        await this.$store.dispatch('fetchWorkOrderResume',this.work_order.id)
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
      })
      .finally(()=>{
        this.internalLoading=false
      })
    },
    async closeProducts(products){
      products.forEach(async p=>{
        const {process_orders}=p
        for (let i=0;i<process_orders.length;i++){
          const process=process_orders[i]
          if(!process.completed) {
            try{
              if(process.machine_equipments.length<1){
                throw new Error('No hay equipos asignados para el proceso '+process.name)
              }
              let processes = []
              processes.push({
                id:parseInt(process.id),
                real_mts2: process.bitacora.length===0?parseFloat(p.mts2):0,
                real_qty:process.bitacora.length===0?parseFloat(p.qty):0,
                ask_completed:true,  
              }) 
              await postProcessOrderComplete({
                processes:processes,
                equipment:process.machine_equipments[0].id,
                notes:''
              })
              .then((res)=>{
                console.log('Proceso '+process.name+' Actualizado')

              })
              .catch((e)=>{
                notifications.showError('Error Realizando el Registro '+e)
              })
            }
            catch(e){
              this.closingOrder=false
              notifications.showError(e.message)
              break
              return
            }
          }
 
        }
      })
    },
    async closeWorkOrder(){
      this.showConfirmCloseOrder=false
      //Revisando que cada proceso tenga un equipo para asignar
      const {products}=this.work_order
      
      await this.closeProducts(products)
      .then(()=>{
        this.$emit('close')
        //Toca setear un time out para refrescar para que el backend pueda
        //procesar la orden completa. 
        setTimeout(()=>{
          this.$emit('refresh')
        },2000)
        
        notifications.showSuccess('Orden de Trabajo Cerrada')
      })
      .catch(()=>notifications.showError('Error actualizando la orden'))
    } 
  }
}
</script>